module.exports = {

    author: "@lgomesp",
    siteTitle: "Lucas Gomes @LGOMESP",
    siteShortTitle: "Lucas Gomes", // Used as logo text in header, footer, and splash screen
    siteDescription: "general.description",
    siteUrl: "https://www.lgomesp.com.br/",
    siteLanguage: "en_US",
    siteIcon: "src/content/favicon.png", // Relative to gatsby-config file

    splashScreen: false, // Set this to true if you want to use the splash screen

    // There are icons available for the following platforms: 
    // Medium, GitHub, LinkedIn, XING, Behance
    socialMedia: [
        {
            name: "E-mail",
            url: "mailto:lucasgomesp22@gmail.com",
            bgColor: 'orange'
        },
        {
            name: "Linkedin",
            url: "https://www.linkedin.com/in/lgomesp/",
            bgColor: '#0077B5'
        },
        {
            name: "Github",
            url: "https://github.com/lgomesp",
            bgColor: '#24292E'
        },
    ],
  
    navLinks: {
        menu: [
            {
                name: "navbar.aboutMe",
                url: "/#about",
            },
            {
                name: "navbar.skills",
                url: "/#skills",
            },
            {
                name: "navbar.projects",
                url: "/#projects",
            },
        ],
        button: {
            name: "navbar.contact",
            url: "/#contact",
            }
        }
    }