
import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const StyledButton = styled.button`
padding: 1.5rem;
background-color: transparent;
font-size: 1.2rem;
font-weight: 700;
text-transform: uppercase;
border: none;
&:hover,
&:focus,
&:active {
  cursor: pointer;
  outline: none;
}
@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
  padding: 1.5rem 0;
  font-size: 0.875rem;
}
`

const LanguageMenu = () => {
  const { i18n } = useTranslation()

  const [language, setLanguage] = useState({
    code: i18n.language === 'pt-BR' ? 'en' : 'pt-BR',
    display: i18n.language === 'pt-BR' ? 'en' : 'pt'
  })

  function handleChange(lang) {
    i18n.changeLanguage(lang)
    lang === 'pt-BR'
      ?
      setLanguage({
        code: 'en',
        display: 'en'
      })
      :
      setLanguage({
        code: 'pt-BR',
        display: 'PT'
      })
  }

  return (
    <StyledButton type="button" onClick={() => handleChange(language.code)}>{language.display}</StyledButton>
  )
}

export default LanguageMenu