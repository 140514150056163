import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import Underlining from "../styles/Underlining"
import ContentWrapper from "../styles/ContentWrapper"
import Logo from "./logo"

const StyledFooter = styled.footer`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
  margin-top: 10rem;
`

const StyledCopyright = styled.div`
  text-align: right;
  && > span {
    color: red;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-links {
      /* Adjust width of links wrapper accordingly */
      width: 10rem;
      display: flex;
      justify-content: space-between;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 15rem;
      }
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()

  return (
    <StyledFooter>
      <StyledContentWrapper>
        <Link to="/" aria-label="home">
          <Logo color="black" size="1.5rem" />
        </Link>
        <StyledCopyright>
          {t('general.copyright')} <span>&#10084;</span> {t('general.and')}&nbsp;
          <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
            <Underlining color="secondary" hoverColor="secondary">
              Gatsby
          </Underlining>
          </a>
        &nbsp;© {new Date().getFullYear()}
        </StyledCopyright>
      </StyledContentWrapper>
    </StyledFooter>
  )
}

export default Footer
