import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import stringTranslationsEN from '../locales/en/translations.json';
import stringTranslationsPT from '../locales/pt-br/translations.json';
import { default as HeroContentEN } from '../content/hero/hero.en.mdx';
import { default as HeroContentPT } from '../content/hero/hero.pt.mdx';
import { default as AboutContentEN } from '../content/about/about.en.mdx';
import { default as AboutContentPT } from '../content/about/about.pt.mdx';

const options = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}

i18next
    .use(LanguageDetector)
    .init({
        detection: options,
        fallbackLng: 'en',
        resources: {
            'pt-BR': {
                translations: {
                    ...stringTranslationsPT,
                    heroContent: HeroContentPT,
                    aboutContent: AboutContentPT
                }
            },
            en: {
                translations: {
                    ...stringTranslationsEN,
                    heroContent: HeroContentEN,
                    aboutContent: AboutContentEN
                }
            }
        },
        ns: ['translations'],
        defaultNS: 'translations',
        returnObjects: true,
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            wait: true,
        },
    });

i18next.languages = ['pt-BR', 'en'];

export default i18next;