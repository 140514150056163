import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Underlining from "../../styles/Underlining";
import Theme from "../../styles/Theme";
export const companies = [{
  name: "Enjoei",
  date: "about.companies.enjoei.date",
  position: "about.companies.enjoei.position",
  location: "about.companies.enjoei.location",
  logo: "./logos/enjoei.png",
  specialization: ["HTML", "CSS", "JavaScript", "Vue"]
}, {
  name: "Quero Educação",
  date: "about.companies.quero.date",
  position: "about.companies.quero.position",
  location: "about.companies.quero.location",
  logo: "./logos/quero.png",
  specialization: ["PHP", "MySQL", "Wordpress", "HTML", "CSS", "JavaScript"]
}, {
  name: "Quinstreet",
  date: "about.companies.quinstreet.date",
  position: "about.companies.quinstreet.position",
  location: "about.companies.quinstreet.location",
  logo: "./logos/quinstreet.png",
  specialization: ["PHP", "MySQL", "Wordpress", "HTML", "CSS", "JavaScript"]
}, {
  name: "Instituto Nacional de Resíduos",
  date: "about.companies.inre.date",
  position: "about.companies.inre.position",
  location: "about.companies.inre.location",
  logo: "./logos/inre.png",
  specialization: ["HTML", "CSS", "JavaScript", "AngularJS"]
}];
export const education = [{
  name: "about.education.mackenzie.name",
  date: "2016 - 2020",
  course: "about.education.mackenzie.course"
}, {
  name: "about.education.senac.name",
  date: "2012 - 2015",
  course: "about.education.senac.course"
}, {
  name: "about.education.mit.name",
  date: "2020",
  course: "about.education.mit.course"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.php"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.vue"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.node"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.scrum"
}];
export const _frontmatter = {
  "title": "about.title",
  "image": "./me.JPG"
};
const layoutProps = {
  companies,
  education,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I'm Lucas, and as you might have already read, I work as Full Stack Developer. `}<strong>{`Pleased to meet you!`}</strong></p>
    <p>{`I created this portfolio using my ability to grasp new information and apply new technologies so I could present yourself and show my projects. If you like what you see `}<span className="line-through">{`or don't`}</span>{`, let me know! Please check out the many ways you can reach me over `}<a href="#contact" rel="nofollow noopener noreferrer" aria-label="Go to contact section"><Underlining color={Theme.colors.tertiary} hoverColor={Theme.colors.secondary} mdxType="Underlining">{`the contact session`}</Underlining></a>{`. I would love to hear you opinion!`}</p>
    <p>{`I am proficient with the core frontend technologies like HTML, CSS and JavaScript, as well as the backend using PHP and MySQL. I find myself working a lot with React, Vue.js and WordPress recently.`}</p>
    <p>{`In my spare time, I'm most likely watching TV series on Netflix, spending time with family and friends or improving my dancing abilities `}<span className="line-through">{`(that might sound doubtful, `}<strong>{`I KNOW`}</strong>{`, but I really appreciate the brazilian Zouk, Vanera Paulista & Sertanejo dance)`}</span>{`. Try finding me `}<a rel="nofollow noopener noreferrer" aria-label="Ir para o vídeo" target="_blank" href="https://www.facebook.com/watch/?v=1555957681220723"><Underlining color={Theme.colors.tertiary} hoverColor={Theme.colors.secondary} mdxType="Underlining">{` in this video`}</Underlining></a>{`.`}</p>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      