import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Underlining from "../../styles/Underlining";
import Theme from "../../styles/Theme";
export const companies = [{
  name: "Enjoei",
  date: "about.companies.enjoei.date",
  position: "about.companies.enjoei.position",
  location: "about.companies.enjoei.location",
  logo: "./logos/enjoei.png",
  specialization: ["HTML", "CSS", "JavaScript", "Vue"]
}, {
  name: "Quero Educação",
  date: "about.companies.quero.date",
  position: "about.companies.quero.position",
  location: "about.companies.quero.location",
  logo: "./logos/quero.png",
  specialization: ["PHP", "MySQL", "Wordpress", "HTML", "CSS", "JavaScript"]
}, {
  name: "Quinstreet",
  date: "about.companies.quinstreet.date",
  position: "about.companies.quinstreet.position",
  location: "about.companies.quinstreet.location",
  logo: "./logos/quinstreet.png",
  specialization: ["PHP", "MySQL", "Wordpress", "HTML", "CSS", "JavaScript"]
}, {
  name: "Instituto Nacional de Resíduos",
  date: "about.companies.inre.date",
  position: "about.companies.inre.position",
  location: "about.companies.inre.location",
  logo: "./logos/inre.png",
  specialization: ["HTML", "CSS", "JavaScript", "AngularJS"]
}];
export const education = [{
  name: "about.education.mackenzie.name",
  date: "2016 - 2020",
  course: "about.education.mackenzie.course"
}, {
  name: "about.education.senac.name",
  date: "2012 - 2015",
  course: "about.education.senac.course"
}, {
  name: "about.education.mit.name",
  date: "2020",
  course: "about.education.mit.course"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.php"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.vue"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.node"
}, {
  name: "about.education.linkedin.name",
  date: "2019",
  course: "about.education.linkedin.courses.scrum"
}];
export const _frontmatter = {
  "title": "about.title",
  "image": "./me.JPG"
};
const layoutProps = {
  companies,
  education,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Eu sou Lucas e, como você já deve ter lido, trabalho como Full Stack Developer. `}<strong>{`Prazer em conhecê-lo!`}</strong></p>
    <p>{`Criei este portfólio utilizando minha habilidade de captar novas informações e aplicar novas tecnologias para que eu pudesse me apresentar e mostrar meus projetos. Se você gostou do resultado `}<span className="line-through">{`ou não`}</span>{`, me avise! Verifique as muitas maneiras pelas quais você pode entrar em contato comigo `}<a href="#contact" rel="nofollow noopener noreferrer" aria-label="Ir para seção de contato"><Underlining color={Theme.colors.tertiary} hoverColor={Theme.colors.secondary} mdxType="Underlining">{` na seção de contato`}</Underlining></a>{`. Eu adoraria ouvir sua opinião!`}</p>
    <p>{`Sou proficiente nas principais tecnologias de front-end como HTML, CSS e JavaScript, bem como back-end usando PHP e MySQL. Tenho trabalhado muito com React, Vue.js e WordPress recentemente.`}</p>
    <p>{`Em meu tempo livre, provavelmente estou assistindo séries de TV na Netflix, passando tempo com minha família e amigos ou melhorando minhas habilidades de dança `}<span className="line-through">{`(pode parecer duvidoso, `}<strong>{`EU SEI`}</strong>{`, mas aprecio muito os ritmos Zouk, Vanera Paulista e Sertanejo)`}</span>{`. Tente me encontrar `}<a rel="nofollow noopener noreferrer" aria-label="Ir para o vídeo" target="_blank" href="https://www.facebook.com/watch/?v=1555957681220723"><Underlining color={Theme.colors.tertiary} hoverColor={Theme.colors.secondary} mdxType="Underlining">{` neste vídeo`}</Underlining></a>{`.`}</p>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      