module.exports = {
  colors: {
    primary: "#000000",
    secondary: "#FFF4D9",
    tertiary: "#F2F2F2",
    text: "#1D1C21",
    background: "#ffffff",
    darkBackground: "blue"
  },
  breakpoints: {
    xs: "480px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
  fonts: {
    primary: "Montserrat, Roboto, sans-serif",
    secondary: "Source Sans Pro, Arial, sans-serif",
  },
  borderRadius: "1rem",
  pageWidth: "62.5rem",
  headerHeight: "6.25rem",
  footerHeight: "7.5rem",
}
