import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "greetings": "hero.greetings",
  "icon": "waving-hand.png",
  "subtitlePrefix": "hero.subtitlePrefix",
  "subtitle": "hero.subtitle",
  "content": "hero.body"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I’m a Full-Stack Web Application Developer based in São Paulo, Brazil. From UI design, frontend and backend development, I have an eye for creating simple and intuitive user experiences, always learning and aplying new technologies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      