import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "greetings": "hero.greetings",
  "icon": "waving-hand.png",
  "subtitlePrefix": "hero.subtitlePrefix",
  "subtitle": "hero.subtitle",
  "content": "hero.body"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Sou Desenvolvedor Web Full-Stack que mora na região de São Paulo, Brasil. Desde o design da interface do usuário, passando pelo desenvolvimento de frontend e backend, gosto de criar experiências de usuário simples e intuitivas, sempre aprendendo e aplicando novas tecnologias.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      